import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, makeStyles } from '@material-ui/core';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import clsx from 'clsx';
import { Fragment } from 'react/jsx-runtime';
import InQStyleGuide, { DEFAULT_PARTICIPANT_COLOUR } from '../../constants';
import ParticipantTile from '../shared/ParticipantTile';
import SharedContentTile from '../shared/SharedContentTile';
import { useActiveSpeaker } from '../../hooks/useActiveSpeaker';
const useStyles = makeStyles((theme) => ({
    viewContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    participantsRow: {
        boxShadow: `0px ${theme.spacing(1)}px ${theme.spacing(1.5)}px 0px rgba(0, 0, 0, 0.25)`,
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        justifyContent: 'center',
        overflowX: 'auto',
        padding: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    tileWrapper: {
        position: 'relative',
        background: InQStyleGuide.grey.light,
        borderRadius: theme.spacing(2),
        minHeight: theme.spacing(12.5),
        minWidth: theme.spacing(12.5),
        maxHeight: theme.spacing(12.5),
    },
    participantTile: {
        aspectRatio: 'unset',
        borderRadius: theme.spacing(2),
        border: `1px solid ${DEFAULT_PARTICIPANT_COLOUR}`,
        height: '100%',
    },
    activeView: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    activeTile: {
        height: '100%',
    },
}));
const ActiveParticipantTile = ({ sharingParticipant, activeSpeaker, meeting, }) => {
    const classes = useStyles();
    return (_jsxs(Fragment, { children: [sharingParticipant && (_jsx(SharedContentTile, { participant: sharingParticipant }, sharingParticipant.id)), !sharingParticipant && activeSpeaker && (_jsx(ParticipantTile, { participant: activeSpeaker, className: classes.activeTile }, activeSpeaker.id)), !sharingParticipant && !activeSpeaker && (_jsx(ParticipantTile, { participant: meeting.self, className: classes.activeTile }, meeting.self.id))] }));
};
/**
 * Participants Gallery View (Speaker view)
 * Displays a scrollable row of participant videos at the top,
 *  and a larger area with the current speaker / shared screen under it
 *
 * Supports 1 shared screen at a time
 */
export default function GalleryView() {
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const [activeSpeaker] = useActiveSpeaker();
    const activeParticipants = useDyteSelector((m) => m.participants.active.toArray());
    const participants = [meeting.self, ...activeParticipants];
    const joinedParticipants = useDyteSelector((m) => m.participants.joined.toArray());
    const selfSharing = useDyteSelector((m) => m.self.screenShareEnabled);
    const otherSharing = joinedParticipants.find((p) => p.screenShareEnabled);
    const sharingParticipant = selfSharing ? meeting.self : otherSharing;
    return (_jsxs(Box, Object.assign({ className: classes.viewContainer }, { children: [_jsx(Box, Object.assign({ className: classes.participantsRow }, { children: participants.map((participant) => {
                    return (_jsx(Box, Object.assign({ className: clsx(classes.tileWrapper) }, { children: _jsx(ParticipantTile, { participant: participant, className: classes.participantTile, fullWidthName: true, hideHandRaisedIcon: true }) }), participant.id));
                }) })), _jsx(Box, Object.assign({ className: classes.activeView }, { children: _jsx(ActiveParticipantTile, { sharingParticipant: sharingParticipant, activeSpeaker: activeSpeaker, meeting: meeting }) }))] })));
}
