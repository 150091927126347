const _ = require("lodash");
const moment = require("moment");
const {
  assertConnection,
  invokeActionAndWait,
  invokeApi,
  invoke,
} = require("./utils");
const { Observable } = require("rxjs");

const bee = require("hive-bee");

const {
  getRaamInstances,
  getManyRaamInstances,
  queryStats,
  queryRaam,
  extractReportProperties,
  getApplicationConfig,
  getAppState,
} = require("./support");

const getPatronsInstances = getRaamInstances("patron");
const getManyPatronsInstances = getManyRaamInstances("patron");
const queryCountAllPatrons = queryRaam("countAllPatrons");
const getCliniciansInstances = getRaamInstances("clinician");
const getManyCliniciansInstances = getManyRaamInstances("clinician");
const getManyCaresInstances = getManyRaamInstances("care");
const queryCaresByCrafts = queryRaam("caresByCrafts");
const queryHourlyVisitCountByDates = queryStats("hourlyVisitCountByDates");
const queryDailyVisitCountByDates = queryStats("dailyVisitCountByDates");
const queryDenialReportByDates = queryStats("denialReportByDates");
const queryVisitReportByDates = queryStats("visitReportByDates");
const queryQueueReportByDates = queryStats("queueReportByDates");

const update = (subscriber, bee) => async () => {
  let newClients = await getPatronsInstances(bee);
  subscriber.next(processInstances(newClients));
};

const updateHourlyVisitReportCount = (subscriber, bee) => () => {
  const startDate = moment().subtract(23, "hours").startOf("hour").format();
  const endDate = moment().add(1, "minutes").format();
  queryHourlyVisitCountByDates(bee, [startDate, endDate])
    .then((report) =>
      _.map(report, (group) => _.pick(group, ["group", "count"]))
    )
    .then((report) => subscriber.next(report));
};

const updateHourlyVisitReportAverage = (subscriber, bee) => () => {
  const startDate = moment().subtract(1, "months").startOf("hour").format();
  const endDate = moment().add(1, "minutes").format();
  queryHourlyVisitCountByDates(bee, [startDate, endDate])
    .then((report) =>
      _.map(report, (group) => _.pick(group, ["group", "average"]))
    )
    .then((report) => subscriber.next(report));
};

const updateDailyVisitReportCount = (subscriber, bee) => () => {
  const startDate = moment().subtract(6, "days").startOf("day").format();
  const endDate = moment().add(1, "minutes").format();
  queryDailyVisitCountByDates(bee, [startDate, endDate])
    .then((report) =>
      _.map(report, (group) => _.pick(group, ["group", "count"]))
    )
    .then((report) => subscriber.next(report));
};

const updateDailyVisitReportAverage = (subscriber, bee) => () => {
  const startDate = moment().subtract(1, "months").startOf("day").format();
  const endDate = moment().add(1, "minutes").format();
  queryDailyVisitCountByDates(bee, [startDate, endDate])
    .then((report) =>
      _.map(report, (group) => _.pick(group, ["group", "average"]))
    )
    .then((report) => subscriber.next(report));
};

const updateAppState = (subscriber) => async () => {
  let state = await getAppState();
  subscriber.next(state);
};

let processInstances = (instances) => {
  return _.map(
    instances,
    _.flow([(p) => _.pick(p, ["id", "created", "properties"])])
  );
};

const observePatrons = () => {
  const connection = assertConnection();
  let { bee } = connection;

  return new Observable((subscriber) => {
    getPatronsInstances(connection.bee).then((initial) =>
      subscriber.next(processInstances(initial))
    );

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:destroy.raam.patron",
        update(subscriber, bee)
      ),
      bee.reactions.setCallback(
        "storage:mutate.raam.patron",
        update(subscriber, bee)
      ),
      bee.reactions.setCallback(
        "storage:create.raam.patron",
        update(subscriber, bee)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const observeHourlyVisitReportCount = () => {
  const { bee } = assertConnection();

  return new Observable((subscriber) => {
    updateHourlyVisitReportCount(subscriber, bee)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:create.stats.visitReport",
        updateHourlyVisitReportCount(subscriber, bee)
      ),
      bee.reactions.setCallback(
        "invocation:cleanup",
        updateHourlyVisitReportCount(subscriber, bee)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const observeHourlyVisitReportAverage = () => {
  const { bee } = assertConnection();

  return new Observable((subscriber) => {
    updateHourlyVisitReportAverage(subscriber, bee)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:create.stats.visitReport",
        updateHourlyVisitReportAverage(subscriber, bee)
      ),
      bee.reactions.setCallback(
        "invocation:cleanup",
        updateHourlyVisitReportAverage(subscriber, bee)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const observeDailyVisitReportCount = () => {
  const { bee } = assertConnection();

  return new Observable((subscriber) => {
    updateDailyVisitReportCount(subscriber, bee)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:create.stats.visitReport",
        updateDailyVisitReportCount(subscriber, bee)
      ),
      bee.reactions.setCallback(
        "invocation:cleanup",
        updateDailyVisitReportCount(subscriber, bee)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const observeDailyVisitReportAverage = () => {
  const { bee } = assertConnection();

  return new Observable((subscriber) => {
    updateDailyVisitReportAverage(subscriber, bee)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:create.stats.visitReport",
        updateDailyVisitReportAverage(subscriber, bee)
      ),
      bee.reactions.setCallback(
        "invocation:cleanup",
        updateDailyVisitReportAverage(subscriber, bee)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const observeAppState = () => {
  const { bee } = assertConnection();

  return new Observable((subscriber) => {
    getAppState().then((initial) => subscriber.next(initial));

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:mutate.raam.appState",
        updateAppState(subscriber)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const getClinicians = () => {
  const connection = assertConnection();
  let { bee } = connection;

  return getCliniciansInstances(bee);
};

const setFlags = (id, flags) => invokeActionAndWait("raam.setFlags")(id, flags);
const setClinician = (id, type, data) =>
  invokeActionAndWait("raam.setClinician")(id, type, data);

const getBlobUrl = (blobId, mode) => {
  const baseUrl = bee.urls.shellUrl();
  return `${baseUrl}/blob/${mode}/${blobId}`;
};

const getDenialReportByDates = async (fromDate, toDate) => {
  const connection = assertConnection();
  return await queryDenialReportByDates(connection.bee, [
    fromDate,
    toDate,
  ]).then(extractReportProperties);
};

const getVisitReportByDates = async (fromDate, toDate) => {
  const connection = assertConnection();
  return queryVisitReportByDates(connection.bee, [fromDate, toDate]).then(
    extractReportProperties
  );
};

const getQueueReportByDates = async (fromDate, toDate) => {
  const connection = assertConnection();
  return await queryQueueReportByDates(connection.bee, [fromDate, toDate]).then(
    extractReportProperties
  );
};

const isRaamAdmin = async () => {
  let { bee } = assertConnection();
  let rv = await bee.auth.hasRole("raamAdmin");
  return rv;
};

const updateCaresByCrafts = (subscriber, bee, crafts) => async () => {
  const cares = await queryCaresByCrafts(bee, [crafts]);
  subscriber.next(processInstances(cares));
};

const observeCaresByCrafts = (crafts) => {
  const connection = assertConnection();
  const { bee } = connection;

  return new Observable((subscriber) => {
    updateCaresByCrafts(subscriber, bee, crafts)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:destroy.raam.care",
        updateCaresByCrafts(subscriber, bee, crafts)
      ),
      bee.reactions.setCallback(
        "storage:mutate.raam.care",
        updateCaresByCrafts(subscriber, bee, crafts)
      ),
      bee.reactions.setCallback(
        "storage:create.raam.care",
        updateCaresByCrafts(subscriber, bee, crafts)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const updateManyCares = (subscriber, bee, ids) => async () => {
  const cares = await getManyCaresInstances(bee, ids);
  subscriber.next(processInstances(cares));
};

const observeCaresByIds = (ids) => {
  const connection = assertConnection();
  let { bee } = connection;

  return new Observable((subscriber) => {
    updateManyCares(subscriber, bee, ids)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:destroy.raam.care",
        updateManyCares(subscriber, bee, ids)
      ),
      bee.reactions.setCallback(
        "storage:mutate.raam.care",
        updateManyCares(subscriber, bee, ids)
      ),
      bee.reactions.setCallback(
        "storage:create.raam.care",
        updateManyCares(subscriber, bee, ids)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const updateManyClinicians = (subscriber, bee, ids) => async () => {
  const clinicians = await getManyCliniciansInstances(bee, ids);
  subscriber.next(processInstances(clinicians));
};

const observeCliniciansByIds = (ids) => {
  const connection = assertConnection();
  let { bee } = connection;

  return new Observable((subscriber) => {
    updateManyClinicians(subscriber, bee, ids)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:destroy.raam.clinician",
        updateManyClinicians(subscriber, bee, ids)
      ),
      bee.reactions.setCallback(
        "storage:mutate.raam.clinician",
        updateManyClinicians(subscriber, bee, ids)
      ),
      bee.reactions.setCallback(
        "storage:create.raam.clinician",
        updateManyClinicians(subscriber, bee, ids)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const updateManyPatrons = (subscriber, bee, ids) => async () => {
  const patrons = await getManyPatronsInstances(bee, ids);
  subscriber.next(processInstances(patrons));
};

const observePatronsByIds = (ids) => {
  const connection = assertConnection();
  let { bee } = connection;

  return new Observable((subscriber) => {
    updateManyPatrons(subscriber, bee, ids)();

    const callbackIds = [
      bee.reactions.setCallback(
        "storage:destroy.raam.patron",
        updateManyPatrons(subscriber, bee, ids)
      ),
      bee.reactions.setCallback(
        "storage:mutate.raam.patron",
        updateManyPatrons(subscriber, bee, ids)
      ),
      bee.reactions.setCallback(
        "storage:create.raam.patron",
        updateManyPatrons(subscriber, bee, ids)
      ),
    ];

    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const observeCheckedInPatronId = () => {
  const connection = assertConnection();
  const { bee } = connection;
  return new Observable((subscriber) => {
    const callbackIds = [
      bee.reactions.setCallback("storage:create.raam.patron", (reaction) =>
        subscriber.next(_.get(reaction, ["details", "id"]))
      ),
    ];
    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

const updateCountAllPatrons = (subscriber, bee) => async () => {
  const result = await queryCountAllPatrons(bee, []);
  subscriber.next(_.get(result, ["0", "count"], 0));
};

const observeCountAllPatrons = () => {
  const connection = assertConnection();
  const { bee } = connection;
  return new Observable((subscriber) => {
    updateCountAllPatrons(subscriber, bee)();
    const callbackIds = [
      bee.reactions.setCallback(
        "storage:destroy.raam.patron",
        updateCountAllPatrons(subscriber, bee)
      ),
      bee.reactions.setCallback(
        "storage:create.raam.patron",
        updateCountAllPatrons(subscriber, bee)
      ),
    ];
    return () => {
      _.forEach(callbackIds, bee.reactions.removeCallback);
    };
  });
};

module.exports = {
  checkIn: (patron, initCares) =>
    invokeActionAndWait("raam.checkInClinician")(patron, initCares),
  observeCheckedInPatronId,
  observePatrons,
  observePatronsByIds,
  observeCountAllPatrons,
  observeCaresByCrafts,
  observeCaresByIds,
  observeCliniciansByIds,
  observeAppState,
  getClinicians,
  getApplicationConfig,
  getGroupHostRoomToken: (userName, patronId, roomName) =>
    invokeApi("raam.getGroupHostRoomToken")(userName, patronId, roomName),
  getHostRoomToken: (userName, patronId, roomName) =>
    invokeApi("raam.getHostRoomToken")(userName, patronId, roomName),
  isRaamAdmin,
  states: {
    toTriage: (id) => invokeActionAndWait("raam.movePatronToTriage")(id),
    toPostTriage: (id) =>
      invokeActionAndWait("raam.movePatronToPostTriage")(id),
    toClinician: (id) => invokeActionAndWait("raam.movePatronToClinician")(id),
    eject: (id, msg, denialType) =>
      invokeActionAndWait("raam.ejectPatron")(id, msg, denialType),
    close: (id) => invokeActionAndWait("raam.closePatron")(id),
  },
  addNote: (id, text) => invokeActionAndWait("raam.addNoteToPatron")(id, text),
  startMeeting: (careId) => invokeActionAndWait("raam.startMeeting")(careId),
  joinMeeting: (careId) => invokeActionAndWait("raam.joinMeeting")(careId),
  endMeeting: (careId) => invokeActionAndWait("raam.endMeeting")(careId),
  endGroupMeeting: (meetingId) => invokeApi("raam.endGroupMeeting")(meetingId),
  setPatientId: (id, patientId) =>
    invokeActionAndWait("raam.setPatientID")(id, patientId),
  releasePatron: (id) => invokeActionAndWait("raam.releasePatron")(id),
  createTwilioMeeting: () => invokeActionAndWait("raam.createTwilioMeeting")(),
  completeTwilioMeeting: (meetingName) =>
    invokeActionAndWait("raam.completeTwilioMeeting")(meetingName),
  removeParticipantFromMeeting: (meetingName, name) =>
    invokeActionAndWait("raam.removeParticipantFromMeeting")(meetingName, name),
  setPatronMeetingInfo: (id, meetingInfo) =>
    invokeActionAndWait("raam.setPatronMeetingInfo")(id, meetingInfo),

  createClinician: (email, displayName, craft, isAdmin, isCommunity, pronoun) =>
    invokeActionAndWait("raam.createClinician")(
      email,
      displayName,
      craft,
      isAdmin,
      isCommunity,
      pronoun
    ),
  deleteClinician: (userId) =>
    invokeActionAndWait("raam.deleteClinician")(userId),
  updateClinician: (
    userId,
    email,
    displayName,
    craft,
    isAdmin,
    isCommunity,
    pronoun
  ) =>
    invokeActionAndWait("raam.updateClinician")(
      userId,
      email,
      displayName,
      craft,
      isAdmin,
      isCommunity,
      pronoun
    ),
  updateClinicianPronoun: (pronoun) =>
    invokeApi("raam.updateClinicianPronoun")(pronoun),

  setIsImportant: (id, state) => setFlags(id, { isImportant: state }),
  setHasAppointment: (id, state) => setFlags(id, { hasAppointment: state }),
  setIsWalkIn: (id, state) => setFlags(id, { isWalkIn: state }),
  setIsPhoneOnly: (id, state) => setFlags(id, { isPhoneOnly: state }),
  setIsVirtual: (id, state) => setFlags(id, { isVirtual: state }),
  setIsGroup: (id, state) => setFlags(id, { isGroup: state }),
  setHasChartCreated: (id, state) => setFlags(id, { hasChartCreated: state }),

  setClinician: (id, type, state, name) =>
    setClinician(id, type, { required: state, name }),
  setHours: (dayNumber, o) =>
    invokeActionAndWait("raam.setHours")(dayNumber, o),
  setBreakHours: (dayNumber, o) =>
    invokeActionAndWait("raam.setBreakHours")(dayNumber, o),
  setEnabledCrafts: (crafts) =>
    invokeActionAndWait("raam.setEnabledCrafts")(crafts),

  setFrontPageMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgOpen", o),
  setClosedPageMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgClosed", o),
  setBreakPageMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgBreak", o),
  setForceClosedPageMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgForcedClose", o),
  setEjectedFromQueueMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgEjectedFromQueue", o),
  setWaitingMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgWaiting", o),
  setVisitCompleteMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgVisitComplete", o),
  setSubstanceUnsupportedMessaging: (o) =>
    invokeActionAndWait("raam.setMessaging")("msgSubstanceUnsupported", o),

  clearChangeStatus: (messageType, messagePart) =>
    invokeActionAndWait("raam.clearChangeStatus")(messageType, messagePart),

  getBlobUrl,

  getDenialReportByDates,
  getVisitReportByDates,
  getQueueReportByDates,
  observeHourlyVisitReportCount,
  observeHourlyVisitReportAverage,
  observeDailyVisitReportCount,
  observeDailyVisitReportAverage,
  closeQueue: () => invokeActionAndWait("raam.closeQueue")(),
  openQueue: () => invokeActionAndWait("raam.openQueue")(),
  isOpen: () => invokeActionAndWait("raam.isOpen")(),

  addCare: (patronId, craft, status, disableMove) =>
    invokeActionAndWait("raam.addCare")(patronId, craft, status, disableMove),
  bulkAddGroupCareAndAssign: (patronIds, craft, disableMove, clinicianId) =>
    invokeApi("raam.bulkAddGroupCareAndAssign")(
      patronIds,
      craft,
      disableMove,
      clinicianId
    ),
  addCareAndAssign: (patronId, craft, status, disableMove, clinicianId) =>
    invokeActionAndWait("raam.addCareAndAssign")(
      patronId,
      craft,
      status,
      disableMove,
      clinicianId
    ),
  removeCare: (patronId, careId, noteType, noteText) =>
    invokeActionAndWait("raam.removeCare")(
      patronId,
      careId,
      noteType,
      noteText
    ),
  bulkRemoveCare: (removalDetails) =>
    invokeApi("raam.bulkRemoveCare")(removalDetails),
  updateCarePath: (patronId, careIds) =>
    invokeActionAndWait("raam.updateCarePath")(patronId, careIds),
  updateCare: (id, o) => invokeActionAndWait("raam.updateCare")(id, o),
  updateClinicConfig: (
    email,
    phoneNo,
    phoneNoExt,
    regionNameEn,
    regionNameFr,
    adminContactName,
    adminAddress,
    adminEmail,
    adminPhoneNo,
    adminPhoneNoExt,
    adminPhoneNo2,
    adminPhoneNoExt2,
    logo
  ) =>
    invokeActionAndWait("raam.updateClinicConfig")(
      email,
      phoneNo,
      phoneNoExt,
      regionNameEn,
      regionNameFr,
      adminContactName,
      adminAddress,
      adminEmail,
      adminPhoneNo,
      adminPhoneNoExt,
      adminPhoneNo2,
      adminPhoneNoExt2,
      logo
    ),
  updateClinicConfigSync: (o) => invokeApi("raam.updateClinicConfigSync")(o),
  clearClinicDirtyText: (field) =>
    invokeApi("raam.clearClinicDirtyText")(field),
  updateClinicMapScreen: (
    enableMapScreen,
    regionTextEn,
    regionTextFr,
    includeRegionMap,
    mapBlob
  ) =>
    invokeActionAndWait("raam.updateClinicMapScreen")(
      enableMapScreen,
      regionTextEn,
      regionTextFr,
      mapBlob,
      includeRegionMap
    ),
  addLocation: (o) => invokeApi("raam.addLocation")(o),
  updateLocation: (id, o) => invokeApi("raam.updateLocation")(id, o),
  clearLocationDirtyText: (id, field) =>
    invokeApi("raam.clearLocationDirtyText")(id, field),
  deleteLocation: (id) => invokeApi("raam.deleteLocation")(id),
  setLocationHours: (id, dayNumber, o) =>
    invokeActionAndWait("raam.setLocationHours")(id, dayNumber, o),
  setLocationBreakHours: (id, dayNumber, o) =>
    invokeActionAndWait("raam.setLocationBreakHours")(id, dayNumber, o),
  addAltService: (o) => invokeApi("raam.addAltService")(o),
  updateAltService: (id, o) => invokeApi("raam.updateAltService")(id, o),
  clearAltServiceDirtyText: (id) =>
    invokeApi("raam.clearAltServiceDirtyText")(id),
  deleteAltService: (id) => invokeApi("raam.deleteAltService")(id),
  createGroupManifest: (o) => invokeApi("raam.createGroupManifest")(o),
  deleteGroupManifest: (o, r) => invokeApi("raam.deleteGroupManifest")(o, r),
  updateGroupManifest: (groupManifestId, o) =>
    invokeApi("raam.updateGroupManifest")(groupManifestId, o),
  rescheduleMeeting: (meetingId, newStartTime, newEndTime, patronMessage) =>
    invokeApi("raam.rescheduleMeeting")(
      meetingId,
      newStartTime,
      newEndTime,
      patronMessage
    ),
  deleteMeeting: (meetingId, r) =>
    invokeApi("raam.deleteMeeting")(meetingId, r),
  addPendingPatronToMeeting: (patronId, careId) =>
    invoke("raam.addPendingPatronToMeeting")(patronId, careId),
  setMeetingRequireHelp: (meetingId, requireHelp) =>
    invoke("raam.setMeetingRequireHelp")(meetingId, requireHelp),
  setPatronRequireHelp: (patronId, requireHelp) =>
    invoke("raam.setPatronRequireHelp")(patronId, requireHelp),
  startBreakout: (careId) => invoke("raam.startBreakout")(careId),
  endBreakout: (careId) => invoke("raam.endBreakout")(careId),
  pauseMeeting: (meetingId) => invoke("raam.pauseMeeting")(meetingId),
  unpauseMeeting: (meetingId) => invoke("raam.unpauseMeeting")(meetingId),
};
