import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DyteDialog, DyteSettingsAudio, DyteSettingsVideo, DyteSwitch, } from '@dytesdk/react-ui-kit';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { Box, makeStyles } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import InQStyleGuide from '../../constants';
import { useMeetingStore } from '../../context';
import { useLocale } from '../../hooks';
const useStyles = makeStyles((theme) => ({
    dialog: {
        position: 'absolute',
        overflow: 'hidden',
    },
    dialogContent: {
        borderRadius: theme.spacing(1),
        backgroundColor: InQStyleGuide.black,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        maxHeight: '100%',
        width: theme.spacing(70),
    },
    dialogHeader: {
        padding: `0 ${theme.spacing(3)}px`,
    },
    dialogBody: {
        flex: 1,
        marginBottom: theme.spacing(3),
        overflow: 'auto',
        padding: `0 ${theme.spacing(3)}px`,
    },
    select: {
        backgroundColor: InQStyleGuide.grey.medium,
        borderRadius: theme.spacing(0.5),
        border: 'none',
        color: InQStyleGuide.white,
        fontSize: '16px',
        padding: theme.spacing(1.5),
        width: '100%',
    },
    switch: {
        boxSizing: 'border-box',
    },
}));
export default function SettingsDialog() {
    const { isBlurred, isMobile, isSettingsVisible, setHideSettings, toggleBlur, setVideoInput, setAudioInput, setAudioOutput, } = useMeetingStore();
    const classes = useStyles();
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const onBlurSwitchChange = useCallback((e) => {
        // Note: DyteSwitch calls this whenever switch value changes or initializes.
        // Make sure we react to direct user action only
        if (isSettingsVisible && e.detail !== isBlurred && toggleBlur) {
            toggleBlur();
        }
    }, [isBlurred, isSettingsVisible, toggleBlur]);
    const onDialogClose = useCallback(() => {
        setHideSettings();
    }, [setHideSettings]);
    // Handle audio/video input/output changes
    useEffect(() => {
        const handleDeviceChange = ({ device }) => {
            if (device.kind === 'audioinput') {
                setAudioInput(device.deviceId);
            }
            else if (device.kind === 'videoinput') {
                setVideoInput(device.deviceId);
            }
            else if (device.kind === 'audiooutput') {
                setAudioOutput(device.deviceId);
            }
        };
        meeting.self.on('deviceUpdate', handleDeviceChange);
        return () => {
            meeting.self.off('deviceUpdate', handleDeviceChange);
        };
    }, [meeting, setAudioInput, setAudioOutput, setVideoInput]);
    return (_jsx(DyteDialog, Object.assign({ className: classes.dialog, open: isSettingsVisible, onDyteDialogClose: onDialogClose }, { children: _jsxs(Box, Object.assign({ className: classes.dialogContent }, { children: [_jsxs(Box, Object.assign({ className: classes.dialogHeader }, { children: [_jsx("h2", { children: languagePack('settings') }), _jsx("hr", {})] })), _jsxs(Box, Object.assign({ className: classes.dialogBody }, { children: [_jsx("h3", { children: languagePack('video') }), _jsx(DyteSettingsVideo, { meeting: meeting, t: languagePack }), !isMobile && (_jsxs(Box, Object.assign({ display: "flex", justifyContent: "space-between" }, { children: [_jsx("span", { children: languagePack('blur_toggle') }), _jsx(DyteSwitch, { className: classes.switch, checked: isBlurred, onDyteChange: onBlurSwitchChange })] }))), _jsx("hr", {}), _jsx("h3", { children: languagePack('audio') }), _jsx(DyteSettingsAudio, { meeting: meeting, t: languagePack }), _jsx("hr", {})] }))] })) })));
}
